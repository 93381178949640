import {Container, Row, Col} from "react-bootstrap/";
import styles from "./Footer.module.css";
import QRImage from "../../assets/images/QR.png";
import FooterList from "./FooterList";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShowImageBigger from "../../common/ImageBiggerPopup/show-image-bigger";
import { getFooterLinks } from "../../api/services/Footer-links-api";
import style from "./FooterList.module.css";
import appBadge from "../../assets/images/icon/app-store-badge-2.png";
import googleBadge from "../../assets/images/icon/google-play-badge-2.png";

const Footer = ({ Links }) => {
   const { t,i18n } = useTranslation();
   const [footerLinks, setFooterLinks] = useState("");
   const [error, setError] = useState("");
   const [showBigImage, setShowBigImage] = useState(false);
   const handleShowBigImage = () => setShowBigImage(true);

   useEffect(() => {
      getFooterLinks()
        .then((res) => {
         setFooterLinks(res.data);
        })
        .catch((err) => {
          setError("Some error happened!");
        });
    }, [])

    const firstLinks = [
      {
         "id" : 0,
         "title" : t("footer.links1"),
         "href" : "https://tarmeem.org.sa/",
      },
      {
         "id" : 1,
         "title" : t("footer.links2"),
         "href" : "https://raqmin.sa/",
      },
      {
         "id" : 2,
         "title" : t("footer.links3"),
         "href" : "https://redseacenter.org.sa/",
      },
      {
         "id" : 3,
         "title" : t("footer.links4"),
         "href" : "https://www.makkahhistorycenter.org/",
      },
    ]
    const secondLinks = [
      {
         "id" : 0,
         "title" : t('help_support.faqs'),
         "href" : process.env.REACT_APP_DARAH_PORTAL_URL+"helpandsupport/faq",
      },
      {
         "id" : 1,
         "title" : t('help_support.UserFeedback'),
         "href" : process.env.REACT_APP_DARAH_PORTAL_URL+"beneficiaryVoice",
      },
      {
         "id" : 2,
         "title" : t('help_support.SearchPortal'),
         "href" : process.env.REACT_APP_DARAH_PORTAL_URL+"searchgate",
      },
      {
         "id" : 3,
         "title" : t('help_support.ServicesPersonsDisabilities'),
         "href" : process.env.REACT_APP_DARAH_PORTAL_URL+"accessibility-services",
      },
      // {
      //    "id" : 4,
      //    "title" : t('help_support.sign_lang'),
      //    "href" : "",
      // },
      {
         "id" : 5,
         "title" : t('help_support.contact_us'),
         "href" : process.env.REACT_APP_DARAH_PORTAL_URL+"contact-us",
      },
    ]
    const today = new Date();
    const yyyy = today.getFullYear()

   return (
      <footer dir={i18n.language == "en" ? "rtl": "rtl"} className={`${styles["fontFooter"]} ${styles["footer"]}`}>
         <div className={styles["footer-main"]}>
            <Container>
               <div>
                  <Row className={`justify-content-center justify-content-md-between align-items-center ${i18n.language === "en" ? "flex-row-reverse" : ""}`}>
                     <Col md={4} sm={12} className={"text-center px-2 mb-5 mb-xl-0"}>
                        <div
                           className={`d-flex justify-content-center justify-content-lg-start align-items-center ${i18n.language === "en" ? "flex-row-reverse" : ""}`}
                           >
                           <img
                              loading="lazy"
                              // height="100px"
                              // width="50%"
                              style={{maxHeight: "80px"}}
                              src={Links?.default_image}
                              alt="شعار الدارة"
                           />
                           <span
                               className={"mx-2"}
                              style={{
                                 height: "77px",
                                 width: "1px",
                                 border: "1px solid #4E647A",
                              }}
                           ></span>
                           <div className="me-2 pe-2">
                              <img
                                 src={Links?.share_image}
                                 style={{maxHeight: "80px"}}
                                 // width="auto"
                                 alt="الصورة الفرعية للدارة"
                              ></img>
                           </div>
                           
                        </div>

                        <p className={` ${i18n.language == "en" ? styles["footer-bio-rtl"] + " text-lg-start text-center" : styles["footer-bio"] + " text-lg-end text-center" }`}>
                        {Links?.description}
                        </p>

                        
                     <Col
                        className={"mt-3 mt-xl-0 mx-auto mb-3 mb-md-0"}
                        style={{
                          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`
                        }}
                     >
                        <div>
                           <div className={`${styles["Qr-content"]} d-flex align-items-center gap-1 justify-content-center justify-content-lg-start`}>
                              <img
                                 style={{cursor: "pointer"}}
                                 onClick={handleShowBigImage}
                                 src={QRImage}
                                 alt="صورة الqr كود للدارة"
                                 title="darah website"
                                 className={"bg-white rounded-2"}
                              />
                              <ShowImageBigger showBigImage={showBigImage} setShowBigImage={setShowBigImage} image={QRImage} />
                              <div className={styles["Apps-links"] + " " + " gap-1"}>
                                 <a
                                    href="https://apps.apple.com/us/developer/king-abdelaziz-foundation-for-research-and-archives/id993308455"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    <img src={appBadge} alt="app store" />
                                 </a>
                                 <a
                                    href="https://play.google.com/store/apps/developer?id=ADDARAH"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                 >
                                    {/* <div className="bg-white rounded-2 d-flex justify-content-center align-items-center" style={{width: "118px", height: "36px"}}>
                                    </div> */}
                                    <img src={googleBadge} alt="google play" />
                                 </a>
                              </div>
                           </div>
                        </div>
                    
                     </Col>
                     </Col>
                     <Col lg={8} className="px-2">

                     <Row className={`${styles["links"]} ${i18n.language === "en" ? "flex-row-reverse" : ""} mx-0`}  style={{marginTop:"3rem",marginBottom:"3rem" }}>
                     <Col
                        lg={5}
                        md={5}
                        // xs={12}
                        className={`mb-3 mb-md-0 px-2`}
                        style={{
                          paddingLeft: `${i18n.language === "en" ? "15px" : ""}`,
                          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`
                        }}
                     >
                        {/* <h3 className={`px-0 ${style["widget-title"]} text-capitalize ${i18n.language === "en" ? "" : style["widget-title-rtl"]}`}>{t("ImportantLinks")}</h3>
                        <FooterList data={firstLinks} /> */}
                        {/* <FooterList data={footerLinks?.important_url} /> */}
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1813.1713322150135!2d46.70825204523008!3d24.646330874374925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0456013cc4d5%3A0x710421b9ad3c5454!2sKing%20Abdulaziz%20Darah!5e0!3m2!1sen!2seg!4v1717586725650!5m2!1sen!2seg" className={styles.footerMap} style={{border: "0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                     </Col>
                     <Col
                        lg={3}
                        md={3}
                        // xs={12}
                        className="px-2"
                        style={{
                          paddingLeft: `${i18n.language === "en" ? "15px" : ""}`,
                          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`
                        }}
                     >
                        <h3 className={`px-0 ${style["widget-title"]} text-capitalize ${i18n.language === "en" ? "" : style["widget-title-rtl"]}`}>{t("footer.HelpandSupport")}</h3>
                        {/* <FooterList data={footerLinks?.around_site} /> */}
                        <FooterList data={secondLinks} />
                     </Col>
                     <Col
                        lg={4}
                        md={4}
                        // xs={12}
                        className="px-2"
                        style={{
                          paddingLeft: `${i18n.language === "en" ? "15px" : ""}`,
                          direction: `${i18n.language === "en" ? "ltr" : "rtl"}`
                        }}
                     >
                           <h3 className={`px-0 ${styles["widget-title"]} text-capitalize ${i18n.language === "en" ? "" : styles["widget-title-rtl"]}`}>{t("footer.contact_us")}</h3>

                           {/* <FooterList data={footerLinks?.footer_contact_us} /> */}

                          <ul className={styles["list-footer-ico"]}>
                          <li className="mb-2">
                                 <div className={"d-flex text-nowrap"}>
                                    <div className="d-flex align-items-center gap-1">
                                       <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                          <path d="M10.0004 11.1922C11.4363 11.1922 12.6004 10.0281 12.6004 8.59219C12.6004 7.15625 11.4363 5.99219 10.0004 5.99219C8.56445 5.99219 7.40039 7.15625 7.40039 8.59219C7.40039 10.0281 8.56445 11.1922 10.0004 11.1922Z" stroke="#A19D86" stroke-width="1.5"/>
                                          <path d="M3.01675 7.07533C4.65842 -0.141339 15.3501 -0.133006 16.9834 7.08366C17.9418 11.317 15.3084 14.9003 13.0001 17.117C11.3251 18.7337 8.67508 18.7337 6.99175 17.117C4.69175 14.9003 2.05842 11.3087 3.01675 7.07533Z" stroke="#A19D86" stroke-width="1.5"/>
                                       </svg>
                                       {" "}
                                       <label className="fs-6 mb-0 text-white">{t("footer.address_label")} </label>
                                    </div>
                                    <span className="fs-6 mb-0 text-white">{t("footer.address_details")}</span>
                                 </div>
                              </li>

                              <li className="mb-2">
                                 <div className="d-flex text-nowrap">
                                    <div className="d-flex align-items-center gap-1">
                                    <i style={{width: "20px", height: "20px", color: "#A19D86", fontSize: "16px"}} className="far fa-mailbox fa-fw d-flex justify-content-center align-items-center"></i>
                                    {" "}
                                    <label className="fs-6 mb-0 text-white">{t("footer.mailbox")} </label>
                                    </div>
                                    <span className="fs-6 mb-0 text-white">{t("footer.mailbox_details")}</span>
                                 </div>
                              </li>
                              <li className="mb-2">
                                 <div className="d-flex text-nowrap">
                                    <div className="d-flex align-items-center gap-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 22 22" fill="none"><path d="M20.97 17.33C20.97 17.69 20.89 18.06 20.72 18.42C20.55 18.78 20.33 19.12 20.04 19.44C19.55 19.98 19.01 20.37 18.4 20.62C17.8 20.87 17.15 21 16.45 21C15.43 21 14.34 20.76 13.19 20.27C12.04 19.78 10.89 19.12 9.75 18.29C8.6 17.45 7.51 16.52 6.47 15.49C5.44 14.45 4.51 13.36 3.68 12.22C2.86 11.08 2.2 9.94 1.72 8.81C1.24 7.67 1 6.58 1 5.54C1 4.86 1.12 4.21 1.36 3.61C1.6 3 1.98 2.44 2.51 1.94C3.15 1.31 3.85 1 4.59 1C4.87 1 5.15 1.06 5.4 1.18C5.66 1.3 5.89 1.48 6.07 1.74L8.39 5.01C8.57 5.26 8.7 5.49 8.79 5.71C8.88 5.92 8.93 6.13 8.93 6.32C8.93 6.56 8.86 6.8 8.72 7.03C8.59 7.26 8.4 7.5 8.16 7.74L7.4 8.53C7.29 8.64 7.24 8.77 7.24 8.93C7.24 9.01 7.25 9.08 7.27 9.16C7.3 9.24 7.33 9.3 7.35 9.36C7.53 9.69 7.84 10.12 8.28 10.64C8.73 11.16 9.21 11.69 9.73 12.22C10.27 12.75 10.79 13.24 11.32 13.69C11.84 14.13 12.27 14.43 12.61 14.61C12.66 14.63 12.72 14.66 12.79 14.69C12.87 14.72 12.95 14.73 13.04 14.73C13.21 14.73 13.34 14.67 13.45 14.56L14.21 13.81C14.46 13.56 14.7 13.37 14.93 13.25C15.16 13.11 15.39 13.04 15.64 13.04C15.83 13.04 16.03 13.08 16.25 13.17C16.47 13.26 16.7 13.39 16.95 13.56L20.26 15.91C20.52 16.09 20.7 16.3 20.81 16.55C20.91 16.8 20.97 17.05 20.97 17.33Z" stroke="#A19D86" stroke-width="1.8" stroke-miterlimit="10"></path></svg>
                                    {" "}
                                    <label className="fs-6 mb-0 text-white">{t("footer.phone")} </label>
                                    </div>
                                    <span className="fs-6 mb-0 text-white">0114011999</span>
                                 </div>
                              </li>
                              <li className="mb-2">
                                 <div className="d-flex text-nowrap">
                                    <div className="d-flex align-items-center gap-1">
                                    <i style={{width: "20px", height: "20px", color: "#A19D86", fontSize: "16px"}} className="far fa-fax fa-fw d-flex justify-content-center align-items-center"></i>
                                    {" "}
                                    <label className="fs-6 mb-0 text-white">{t("footer.fax")} </label>
                                    </div>
                                    <span className="fs-6 mb-0 text-white">0114013597</span>
                                 </div>
                              </li>

                              <li className="mb-2">
                                 <div className="d-flex text-nowrap">
                                    <div className="d-flex align-items-center gap-1">
                                    <svg className="mx-0" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                       <path d="M14.166 17.0837H5.83268C3.33268 17.0837 1.66602 15.8337 1.66602 12.917V7.08366C1.66602 4.16699 3.33268 2.91699 5.83268 2.91699H14.166C16.666 2.91699 18.3327 4.16699 18.3327 7.08366V12.917C18.3327 15.8337 16.666 17.0837 14.166 17.0837Z" stroke="#A19D86" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                       <path d="M14.1673 7.5L11.559 9.58333C10.7006 10.2667 9.29231 10.2667 8.43398 9.58333L5.83398 7.5" stroke="#A19D86" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {" "}
                                    <label className="fs-6 mb-0 text-white">{t("footer.email_label")} </label>
                                    </div>
                                    <span className="fs-6 mb-0 text-white">{t("footer.email_details")}</span>
                                 </div>
                              </li>
                           </ul>
                     </Col>
                     </Row>

                     </Col>
                  </Row>
               </div>

                <div className={styles["mobile_social_media"]}>
                    <SocialMedia Links = {Links} />
                </div>

               <Row className="mx-0">
                  <hr className={styles.footerHr} />
               </Row>
               <Row style={{direction: `${i18n.language === "en" ? "ltr" : "rtl"}`}} className={`mx-0 ${styles["rights"]}`}>
                  <Col lg={7} md={7} sm={12}>
                     <div className="fs-6 text-center text-md-start">
                        {t(`footer.right`)}
                        {yyyy}
                     </div>
                  </Col>
                  <Col lg={5} md={5} sm={12}
                     className={`${styles.footerNavItems} d-flex justify-content-end flex-column flex-sm-row text-center text-sm-right mt-3 mt-md-0`}
                  >
                     {/* <Link to={"/about-us"}>{t('footer.about_darah')}</Link> */}
                     {/* <Link to={"#"}>{t('footer.terms_of_use')}</Link> */}
                     {/* <Link to={"/privacy-policy"}>{t('footer.privacy_policy')}</Link> */}
                     {/* <Link to={"/helpandsupport/FAQ"}>{t('footer.faq')}</Link> */}
                     {/* <Link to={"/contact-us"}>{t("footer.contact_us")}</Link> */}
                     <SocialMedia Links = {Links} />
                  </Col>
               </Row>
            </Container>
         </div>
      </footer>
        );
};

const SocialMedia = ({Links}) => {
    return <div className={styles["footer-social"]}>
        <ul className="d-flex align-items-center">
        {Links?.twitter && <>
                <li>
                    <a
                        href={Links?.twitter}
                        target={"_blank"}
                        aria-label="Twitter"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none">
                        <path d="M8.22232 6.01792L13.0006 0.583252H11.8687L7.71797 5.30113L4.40526 0.583252H0.583496L5.59406 7.7182L0.583496 13.4166H1.7154L6.09584 8.43328L9.59506 13.4166H13.4168M2.12392 1.41843H3.86284L11.8678 12.6224H10.1285" fill="white"/>
                        </svg>
                     </a>
                </li>
            </>}
        {Links?.linkedin && <>
                <li>
                    <a
                        href={Links?.linkedin}
                        target={"_blank"}
                        aria-label="linkedin"
                    >
                        <i class="fab fa-linkedin-in fa-sm"></i>
                    </a>
                </li>
            </>}
            {Links?.facebook && <>
                <li>
                    <a
                        href={Links?.facebook}
                        target={"_blank"}
                        aria-label="Facebook"
                    >
                        <i className="fab fa-facebook-f"></i>
                    </a>
                </li>
            </>}
            {Links?.whatsapp && <>
                <li>
                    <a
                        href={"tel:+" + Links?.whatsapp}
                        target={"_blank"}
                        aria-label="phone"
                    >
                        <i class="fas fa-phone-alt fa-sm"></i>
                    </a>
                </li>
            </>}
            {Links?.instagram && <>
                <li>
                    <a
                        href={Links?.instagram}
                        target={"_blank"}
                        aria-label="Instagram"
                    >
                        <i className="fab fa-instagram"></i>
                    </a>
                </li>
            </>}
            {Links?.youtube && <>
                <li>
                    <a
                        href={Links?.youtube}
                        target={"_blank"}
                        aria-label="youtube"
                    >
                        <i className="fab fa-youtube"></i>
                    </a>
                </li>
            </>}
            {Links?.googleplus && <>
                <li>
                    <a
                        href={Links?.googleplus}
                        target={"_blank"}
                        aria-label="youtube"
                    >
                  <i class="fab fa-google-plus-g fa-sm"></i>
                  </a>
                </li>
            </>}
        </ul>
    </div>
}
export default Footer;
