import "./header.css";
import NavbarContainer from "./NavbarContainer";
import {useLocation} from "react-router-dom";

const Header = ({Links}) => {
  let location = useLocation();  
  const whichPage = location.pathname
 
  return (
    <>
      <header
        className={"header-one"}
      >
        <div className={`${"site-navigation " } homeHeader`} style={{backgroundColor: whichPage != "/" ? "#425260" : ""}}>
                <NavbarContainer Links={Links}/>
        </div>
      </header>
    </>
  );
};

export default Header;
