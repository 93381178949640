import Dropdown from "./Dropdown";
import { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const MenuItems = ({ items, depthLevel, setCollapseMenu }) => {
   const [dropdown, setDropdown] = useState(false);
   const [active, setActive] = useState(false);
   let ref = useRef();
   const location = useLocation();

   useEffect(() => {
      const handler = (event) => {
         if (dropdown && ref.current && !ref.current.contains(event.target)) {
            setDropdown(false);
         }
      };
      document.addEventListener("mousedown", handler);
      document.addEventListener("touchstart", handler);
      return () => {
         // Cleanup the event listener
         document.removeEventListener("mousedown", handler);
         document.removeEventListener("touchstart", handler);
      };
   }, [dropdown]);  
         
   useEffect(() => {
      if (location.pathname === items.url) {
         setActive(true);
      } else {
         setActive(false);
      }
   }, [location, items]);
   const onMouseEnter = () => {
      window.innerWidth > 960 && setDropdown(true);
   };

   const onMouseLeave = () => {
      window.innerWidth > 960 && setDropdown(false);
   };

   const handleMouseEnterLeave = () => {
      if (dropdown === true) {
      window.innerWidth > 960 && setDropdown(false);
      }else{
         window.innerWidth > 960 && setDropdown(true);
      }
   };

   return (
      <li
         onMouseEnter={onMouseEnter}
         onMouseLeave={onMouseLeave}
         className="menu-items position-static"
         onClick={onMouseLeave}
         ref={ref}
      >
         {items.submenu ? (
            <>
               <Link
                  aria-haspopup="menu"
                  aria-expanded={dropdown ? "true" : "false"}
                  onClick={(event) => {
                     event.preventDefault();
                     return setDropdown((prev) => !prev);
                  }}
                  className={"dropdown-toggle d-flex justify-content-between hoverOnLink notBtnsHover"}
                  data-toggle={"dropdown"}
                  to={"#!"}
               >
                  {items.title}{" "}
                  {depthLevel > 0 ? (
                     <span className="d-flex align-items-center justify-content-center"><i class="fas fa-angle-down fa-fw align-middle"></i></span>
                  ) : (
                     <span className="d-flex align-items-center justify-content-center"><i class="fas fa-angle-down fa-fw align-middle"></i></span>

                  )}
               </Link>
               <Dropdown
                  depthLevel={depthLevel}
                  dropdown={dropdown}
                  submenus={items.submenu}
                  setCollapseMenu={setCollapseMenu}
               />
            </>
         ) : 
         items?.url?.includes("http") && items?.templateType === "journal" ? (
            <>
               <a
                  href={items.url}
                  target={"_blank"}
                  rel="noreferrer"
               >
                  {items.title}
               </a>
            </>
         ) : (
            <NavLink
               activeclassname="active"
               className={"notBtnsHover"}
               onClick={() => {
                  setDropdown(false);
                  setActive(true);
                  setCollapseMenu?.(false);
               }}
               isActive={() => location.pathname === items.url}
               to={items.url}
            >
               {items.title}
            </NavLink>
         )}
      </li>
   );
};

export default MenuItems;
