import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import { logout } from "../../api/services/auth-api/logout-api";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { config } from "../../api/axios";
import { useAuth } from "../../context/authContext/authContext";
import { getNotificationsCount } from "../../api/services/notifications-api/notifications-api";
import "./header.css";
const NavbarIconsMobileScreen = ({setCollapseMenu}) =>
   {
      const navigate = useNavigate();
      let ele = localStorage.getItem("token");
      const { isAuthenticated, setIsAuthenticated, updateAuthState } =
         useAuth();
      const [notificationsCount, setNotificationsCount] = useState(0);

      if (!ele) {
         ele = sessionStorage.getItem("token");
      }

      useEffect(() => {
         if (ele === null || ele === ""){
            setIsAuthenticated(false)
            localStorage.removeItem("token");
            localStorage.removeItem("isAuthenticated");
            updateAuthState(false);

         } else setIsAuthenticated(true);
      }, [ele, setIsAuthenticated]);



      const Exit = async () => {
         try {
            const response = await logout();
            if (response.data === "Done") {
               navigate("/account-management");
               updateAuthState(false);
               localStorage.removeItem("token");
               sessionStorage.removeItem("token");
               localStorage.removeItem("isAuthenticated");
            }
         } catch(err) {
            console.log("err: ", err)
         }
      };
      const { t } = useTranslation();
      const { i18n } = useTranslation();
      const [language, setLanguage] = useState(i18n.language);
      const handleLanguageChange = (newLanguage) => {
         if (newLanguage !== language) {
            setLanguage(newLanguage);
            i18n.changeLanguage(newLanguage);
            config.headers["Accept-Language"] = i18n.language;
            window.location.reload();
         }
      };

      useEffect(() => {
         const fetchData = async () => {
            try {
               if (isAuthenticated) {
                  const res = await getNotificationsCount();
                  setNotificationsCount(res?.total_count ?? 0);
               }
            } catch (err) {
               console.error("Error fetching notifications count:", err);
            }
         };
   
         fetchData();
      }, [notificationsCount]);

      return <>

            <li className={`menu-items`}>
               <Dropdown className={`d-flex flex-column justify-content-${i18n.language == "en" ? "left" : "right"}`}>
                  <Dropdown.Toggle
                     className={
                        "d-flex align-items-center justify-content-between my-0 notBtnsHover rounded-0"
                     }
                     style={{fontSize: "0.8rem"}}
                  >
                     <div className={"text-capitalize"}>
                        {language === "ar" ? "اللغة" : "Language"}
                     </div>
                     
                     <i style={{padding: "6px 10px", marginTop: "7px", fontSize: "0.8rem"}} className="fas fa-angle-down fa-fw align-middle"></i>
                     
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{left: 0, right: 0}}>

                     {language === "en" ? <>
                     <Dropdown.Item
                           role="button"
                           className="py-2 notBtnsHover fw-semibold"
                           style={{borderBottom: "1px solid #A19D86" , color: "#A19D86"}}
                        >
                           English
                        </Dropdown.Item>
                        <Dropdown.Item
                             className="py-2 notBtnsHover fw-semibold"
                           role="button"
                           onClick={() => handleLanguageChange("ar")}
                           style={{color: "#A19D86"}}
                        >
                           العربية
                        </Dropdown.Item>

                     </> : <>
                     <Dropdown.Item
                           role="button"
                           onClick={() => handleLanguageChange("en")}
                           className="py-2 notBtnsHover fw-semibold"
                           style={{borderBottom: "1px solid #A19D86" , color: "#A19D86"}}
                        >
                           English
                        </Dropdown.Item>
                        <Dropdown.Item
                             className="py-2 notBtnsHover fw-semibold"
                           role="button"
                           style={{color: "#A19D86"}}
                        >
                           العربية
                        </Dropdown.Item>


                     </>}
                  </Dropdown.Menu>
                  

               </Dropdown>
            </li>
               
           

            {isAuthenticated && (
               <>
               <li onClick={() => setCollapseMenu(false)} className={`menu-items`}>
                  <Link
                     to={`/profile?tabName=notifications`}
                     className="position-relative profileMenuLinks p-0 notBtnsHover"
                  >
                     {t("header.notifications")}
                     <span
                        className="badge"
                        id="lblCartCount"
                     >
                        {" "}
                        {notificationsCount}{" "}
                     </span>
                  </Link>
               </li>
               
               <li onClick={() => setCollapseMenu(false)} className={`menu-items`}>
                  <Link
                     to={"/profile?tabName=personalProfile"}
                     className={"dropdown-item profileMenuLinks notBtnsHover mdWidth"}
                  >
                     {t("header.personal_profile")}
                  </Link>
               </li>
               </>
            )}

            <li className={`menu-items`}>
               {isAuthenticated ? (
                     <Dropdown.Item className="profileMenuLinks notBtnsHover mdWidth" onClick={Exit}>
                        {t("header.sign_out")}
                     </Dropdown.Item>
                  ) : (
                     <Dropdown.Item>
                        <Link className="profileMenuLinks notBtnsHover mdWidth" to={"/account-management"}>
                           {t("header.sign_in")}
                        </Link>
                     </Dropdown.Item>
                  )}
            </li>
      </>        

   };

export default NavbarIconsMobileScreen;
