import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import i18n from './../../i18next';

export default function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [pathname]);

    const [showButton, setShowButton] = React.useState(false);

    useEffect(() => {
        const checkScrollTop = () => {
            const scrollHeight = 200;
            if (!showButton && window.scrollY > scrollHeight) {
                setShowButton(true)
            } else if (showButton && window.scrollY <= scrollHeight) {
                setShowButton(false)
            }
        }
        window.addEventListener('scroll', checkScrollTop)
        return () => {
            window.removeEventListener('scroll', checkScrollTop)
        }
    })

    const scrollToTop = () => {
        document.documentElement.scrollTo({
            top: 0, left: 0, behavior: "smooth"
        });
    }

    return <div className={`
    position-fixed cursor-pointer text-white p-2 rounded-pill d-flex justify-content-center align-items-center
    ${showButton ? 'show' : 'd-none'}
    `}
                style={{
                    zIndex: 333, width: "40px", height: "40px", bottom: "10px", [i18n.language == "en" ? "left" : "right"]: "80px", "background": "#647683", cursor: "pointer"
                }} onClick={scrollToTop}>
        <i className="fas fa-chevron-up fs-6"></i>
    </div>;
}
