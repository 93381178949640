import Dropdown from "react-bootstrap/Dropdown";
import { useEffect, useState } from "react";
import { logout } from "../../api/services/auth-api/logout-api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { config } from "../../api/axios";
import { useAuth } from "../../context/authContext/authContext";
import { getNotificationsCount } from "../../api/services/notifications-api/notifications-api";
import "./header.css";

const NavbarIcons = () =>
   {
      const navigate = useNavigate();
      const location = useLocation();
      let ele = localStorage.getItem("token");
      const { isAuthenticated, setIsAuthenticated, updateAuthState } =
         useAuth();
      const [notificationsCount, setNotificationsCount] = useState(0);

      if (!ele) {
         ele = sessionStorage.getItem("token");
      }

      useEffect(() => {
         if (ele === null || ele === ""){
            setIsAuthenticated(false)
            localStorage.removeItem("token");
            localStorage.removeItem("isAuthenticated");
            updateAuthState(false);

         } else setIsAuthenticated(true);
      }, [ele, setIsAuthenticated]);



      const Exit = async () => {
         try {
            const response = await logout();
            if (response.data === "Done") {
               navigate("/account-management");
               updateAuthState(false);
               localStorage.removeItem("token");
               sessionStorage.removeItem("token");
               localStorage.removeItem("isRemember");
               localStorage.removeItem("isAuthenticated");
            }
         } catch(err) {
            console.log("err: ", err)
         }
      };
      const { t } = useTranslation();
      const { i18n } = useTranslation();
      const [language, setLanguage] = useState(i18n.language);
      const handleLanguageChange = (newLanguage) => {
         if (newLanguage !== language) {
            setLanguage(newLanguage);
            i18n.changeLanguage(newLanguage);
            config.headers["Accept-Language"] = i18n.language;
            window.location.reload();
         }
      };

      useEffect(() => {
         const fetchData = async () => {
            try {
               if (isAuthenticated) {
                  const res = await getNotificationsCount();
                  setNotificationsCount(res?.total_count ?? 0);
               }
            } catch (err) {
               console.error("Error fetching notifications count:", err);
            }
         };
   
         fetchData();
      }, [location, notificationsCount]);

      return (
         <div
            className={`left-list-icon align-items-center justify-content-${
               language == "en" ? "start" : "end"
            }`}
         >
            <div
               className={`d-flex`}
            >
               <Link
                  state={{ isPublicSearch: true, searchKey: null }}
                  to="/searchdarah"
                  className="list-group-item d-flex align-items-center hideHeavyNav"
               >
                  <i className={`fal fa-search text-white fs-6 ${isAuthenticated ? "mx-2" : "mx-1"}`}></i>
               </Link>

               {isAuthenticated ? <>
               <Link
                  to={process.env.REACT_APP_DARAH_STORE_URL + "favorites"}
                  className="list-group-item d-flex align-items-center hideHeavyNav"
                  >
                  <i class="fal fa-heart text-white fs-6 mx-1"></i>
               </Link>
               </> : <></>}

               <Link
               to={process.env.REACT_APP_DARAH_STORE_URL + "cart"}
               className="list-group-item d-flex align-items-center hideHeavyNav"
               >
                  {" "}
                  <i class="fal fa-shopping-cart text-white fs-6 mx-2"></i>
               </Link>

               
               <Dropdown className="mx-1 mx-md-0">
                  <Dropdown.Toggle
                     className={
                        "d-flex justify-content-between align-items-center my-0 p-0"
                     }
                  >
                     <i className="fas fa-chevron-down d-flex justify-content-center align-items-center fa-xs fa-fw"></i>
                     <div className="d-flex justify-content-center align-items-center px-1"><i class="fal fa-globe fs-6"></i></div>
                  </Dropdown.Toggle>
                  {language === "en" ? (
                     <Dropdown.Menu>
                        <Dropdown.Item
                           role="button"
                           onClick={() => handleLanguageChange("en")}
                           className="fs-6"
                        >
                           English
                        </Dropdown.Item>
                        <Dropdown.Item
                             className="fs-6"
                           role="button"
                           onClick={() => handleLanguageChange("ar")}
                        >
                           العربية
                        </Dropdown.Item>
                     </Dropdown.Menu>
                  ) : (
                     <Dropdown.Menu>
                        <Dropdown.Item className="fs-6"
                           onClick={() => handleLanguageChange("en")}
                        >
                           English
                        </Dropdown.Item>
                        <Dropdown.Item className="fs-6"
                           onClick={() => handleLanguageChange("ar")}
                        >
                           العربية
                        </Dropdown.Item>
                     </Dropdown.Menu>
                  )}
               </Dropdown>

               <div className={`position-relative d-flex ${!isAuthenticated ? "mx-2" : ""}`}>
                  {!isAuthenticated ? (
                     <Link to={"/account-management"}>
                        <div
                           className={
                              "userIconLog rounded-2 d-flex justify-content-between align-items-center"
                           }
                        >
                           <div className={"loginWord text-center"}>
                              {t("personalcontent.sign_in")}
                           </div>
                        </div>
                     </Link>
                  ) : (
                     <>
                        <span
                           className="badgeCart badge"
                           style={{[i18n.language == "en" ? "right" : "left"]: "-5px" }}
                           id="lblCartCount"
                        >
                           {" "}
                           {+notificationsCount || 0}{" "}
                        </span>
                        <Dropdown className={"px-1 d-flex align-items-center"}>
                           <Dropdown.Toggle
                              id="dropdown-basic"
                              className={"userIcon pb-0 profileIcon"}
                           >
                              <i className={"far fa-user"}></i>
                           </Dropdown.Toggle>
                           <Dropdown.Menu style={{left: i18n.language == "en" ? "auto" : 0, right: i18n.language == "en" ? 0 : "auto",}} className={"headerSideIconsMenu"}>
                              {isAuthenticated && (
                                 <Link
                                    to={"/profile?tabName=personalProfile"}
                                    className={"dropdown-item profileMenuLinks"}
                                 >
                                    <i
                                    style={{width: "25px", height: "35px"}}
                                       aria-hidden={"true"}
                                       className={`far fa-user-circle ${i18n.language == "en" ? "en-icon" : ""}`}
                                    ></i>
                                    {t("header.personal_profile")}
                                 </Link>
                              )}

                              {isAuthenticated && (
                                 <Dropdown.Item>
                                    <Link
                                       to={`/profile?tabName=notifications`}
                                       className="position-relative profileMenuLinks p-0"
                                    >
                                       <i
                                       style={{width: "25px", height: "35px"}}
                                          aria-hidden={"true"}
                                          className={`fal fa-bell-plus ${i18n.language == "en" ? "en-icon" : ""}`}
                                       ></i>{" "}
                                       {t("header.notifications")}
                                       <span
                                          className="badge mx-2"
                                          id="lblCartCount"
                                       >
                                          {" "}
                                          {notificationsCount}{" "}
                                       </span>
                                    </Link>
                                 </Dropdown.Item>
                              )}

                              {isAuthenticated ? (
                                 <Dropdown.Item className="profileMenuLinks" onClick={Exit}>
                                    <i
                                    style={{width: "25px", height: "35px"}} className={`fas fa-power-off ${i18n.language == "en" ? "en-icon" : ""}`}></i>
                                    {t("header.sign_out")}
                                 </Dropdown.Item>
                              ) : (
                                 <Dropdown.Item>
                                    <Link className="profileMenuLinks" to={"/account-management"}>
                                       <i
                                       style={{width: "25px", height: "35px"}} className={`fas fa-power-off ${i18n.language == "en" ? "en-icon" : ""}`}></i>{" "}
                                       {t("header.sign_in")}
                                    </Link>
                                 </Dropdown.Item>
                              )}
                           </Dropdown.Menu>
                        </Dropdown>
                     </>
                  )}
               </div>
               
            </div>
         </div>
      );
   };

export default NavbarIcons;
