import pattern from "../../assets/images/pattern/footer-pattern.png";
import "./header-with-background.css";

const styles = {
    content: "",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    // backgroundColor: "rgba(66, 84, 100, 0.88)",
    // backgroundImage: `url(${pattern})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    zIndex: "2",
    // opacity: 0.7
};

const HeaderWithBackground2 = ({backgroundImg, title, headerBody}) => {
    return (
        <div style={{backgroundImage: `url(${backgroundImg})`, backgroundPosition: "top", backgroundSize: 'cover', backgroundRepeat: "no-repeat", position: "relative"}} className={"headlineContainer2 text-center"}>
            <div className={"headlineContent"}>
                <div className={"mainSubTitle-allPages"}>
                    {headerBody}
                </div>
                <div className="my-4 py-4"></div>
                {/* <h2 data-toggle="tooltip" data-html="true" title={title} className="mainTitle-allPages my-2">{title?.length > 50 ? title?.slice(0, 50)+"..." : title}</h2> */}
            </div>
            <div className="background-overlay" style={styles}></div>
        </div>
    );
};

export default HeaderWithBackground2;