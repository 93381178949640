import { axios, baseURL, config } from "../../axios";

export const getEservicesHome = (page = 1, limit = 15) => {
    return axios
        .get(baseURL + `services?limit=${limit}&page=${page}`, config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getEservicesMenuItems = () => {
    return axios
        .get(baseURL + "services", config)
        .then(function (response) {
            return response.data.data.data.map((obj) => {
                const slug = obj.slug;
                const name = obj.name;
                return { title: name, url: `/e-services/service-details/${slug}` };
            })
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const getDigitalContentMenuItems = () => {
    return axios
        .get(baseURL + "categories", config)
        .then(function (response) {
            return response.data.data.data.map((obj) => {
                const slug = obj.slug;
                const name = obj.name;
                if(obj.template == "newspaper") {
                    return { title: name, url: `/digital-content/newspaper/${slug}`, templateType: obj?.template };
                } else if (obj.template == "journal" && (obj.type_model == "darah_journal")){
                    return { title: name, url: process.env.REACT_APP_DARAH_JOURNAL_URL, templateType: obj?.template };
                }
                // else if (obj.template == "journal" && (obj.type_model == "darah_journal")){
                //     return { title: name, url: process.env.REACT_APP_DARAH_JOURNAL_URL+"scientific-reviews-journal", templateType: obj?.template};
                // }
                else if (obj.template == "journal" && (obj.type_model == "darah_journal")){
                    return { title: name, url: process.env.REACT_APP_DARAH_JOURNAL_URL+"journal/"+obj.slug, templateType: obj?.template};
                }
                else if (obj.template == "journal"){
                    return { title: name, url: `/digital-content/magazine/${slug}`, templateType: obj?.template };
                }
                else if (obj.template == "encyclopedia"){
                    return { title: name, url: `/digital-content/encyclopedia/${slug}`, templateType: obj?.template };
                }
                else if (obj.template == "road"){
                    return { title: name, url: `/digital-content/road/${slug}`, templateType: obj?.template };
                }
            })
        })
        .catch(function (error) {
            console.log(error);
        });
};


export const searchEservices = (keyword, limit = 15, page = 1) => {
    const searchURL = `${baseURL}e-services/search?text=${encodeURIComponent(keyword)}&limit=${limit}&page=${page}`;

    return axios
        .get(searchURL, config)
        .then(function (response) {
            return response.data.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};


export const getEservicesHeader = () => {
    return axios(baseURL + `e-services/pages/services`, config)
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
};
