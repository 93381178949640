import {createContext, useContext, useEffect, useState} from "react";
import {getPersonalProfile} from "../../api/services/personal-profile/personal-profile-api";
import {getServiceOrders} from "../../api/services/Eservice-api";
import { CheckToken } from "../../api/services/checkToken-api";

export const AuthContext = createContext();
export const useAuth = () => useContext(AuthContext);


export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Get the authentication state from localStorage or set it to false if not present
        const storedAuth = localStorage.getItem("isAuthenticated");
        return storedAuth ? JSON.parse(storedAuth) : false;
    });
    const [profileData , setProfileData] = useState([]);
    const [orders, setOrders] = useState([]);
    const [activeTab, setActiveTab] = useState("login");

    useEffect(() => { 
          
        (async () => {
            if (isAuthenticated) {
                try {
                    const profileResponse = await getPersonalProfile();
                    setProfileData(profileResponse.data);

                    // const ordersResponse = await getServiceOrders();
                    // setOrders(ordersResponse.data);
                } catch (error) {
                    console.error("Error retrieving personal profile or service orders:", error);
                }
            }
        })();
    }, [isAuthenticated]);

    const updateAuthState = (newAuthState) => {
        setIsAuthenticated(newAuthState);
        localStorage.setItem("isAuthenticated", JSON.stringify(newAuthState));
    };
    
    // localStorage.setItem("profileData", JSON.stringify(profileResponse.data));
    // JSON.parse(localStorage.getItem("profileData"))
    return (
        <AuthContext.Provider value={{  isAuthenticated, setIsAuthenticated, profileData, activeTab, setActiveTab, orders, updateAuthState }}>
          {children}
        </AuthContext.Provider>
      );
    };
 export default AuthProvider;
