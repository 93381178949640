import { axios, baseURL, config } from "../../../axios";

export const getHallPalace = () => {
    return axios(baseURL + "media-centers/hall?limit=8", config)
        .then(function (response) {
            return response.data.data.data.map((obj) => {
                const slug = obj.slug;
                const name = obj.name;
                return { title: name, url: `/media-center/hall/${slug}` };
            })
        })
        .catch(function (error) {
            console.log(error);
        });
};