import styles from './page-loader.module.css';

function BlankPageLoader() {
    return (
        <div className={styles.view}>
            <div className={styles.loaderContainer}>            
            </div>
        </div>
    );
}

export default BlankPageLoader;