import "./Digital-Content/digital-content.css";
import PortalSlider from "./Slider/PortalSlider";
import './Portal.css';
import { useEffect, useState, lazy, Suspense } from "react";
import { getHomeSections } from "../../api/services/portal";
import BlankPageLoader from "../../components/PageLoader/blank-page-loader";

const MediaCenter = lazy(() => import('./MediaCenter/MediaCenter'));
const EServicesTap = lazy(() => import('./Digital-Content/e-services-tab'));
const DigitalContentTab = lazy(() => import('./Digital-Content/digital-content-tab-v2'));
const StoreAndMagazine = lazy(() => import('./StoreAndMagazine/StoreAndMagazine'));
const PortalAnnouncement = lazy(() => import('./Portal-Announcement/portal-announcement'));


const Portal = () => {
    const [homeSections, setHomeSections] = useState({})
    const [pageLoading, setPageLoading] = useState(true);

    useEffect(() => {
        getHomeSections()
           .then((res) => {
                 setHomeSections(res?.data);
                //  console.log(res?.data);
                 
                 setPageLoading(false);
            })
            .catch((err) => {
                console.log("error happened", err);
                setPageLoading(false);
            });
    }, []);

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <PortalSlider
                homeSections={homeSections}
            />
            <Suspense fallback={<BlankPageLoader/>}>
            <EServicesTap
                title={homeSections?.service?.title}
                abstract={homeSections?.service?.abstract}
                pageLoading={pageLoading}
            />
            <MediaCenter news={homeSections?.news} events={homeSections?.events} />
            {/* <DigitalContentTab
                title={homeSections?.digital_content?.title}
                abstract={homeSections?.digital_content?.abstract}
            /> */}
            <StoreAndMagazine
                magazineTitle={homeSections?.darah_journal?.title}
                magazineAbstract={homeSections?.darah_journal?.abstract}
            />
            {/* <PortalAnnouncement /> */}
                </Suspense>
        </>
    );
};
export default Portal;
