import { Link } from "react-router-dom";
import styles from "./FooterList.module.css";

const FooterList = (props) => {
    return (
    <>
      <ul className={styles["list-arrow"]}>
        {props?.data?.map(item => {
          return (
            item?.image? <>
            <li key={item.id} className="d-flex align-items-center">
              <div className="d-flex align-items-center gap-1">
                  <img src={item?.image} alt={item?.title} style={{width: "20px", height: "20px"}} />
                  {" "}
                  <label className="fs-6 mb-0 text-white">{item?.title}</label>
              </div>
            </li>
            </> :
              <>
                  <li key={item.id} className="d-flex align-items-center">
                      {item.url ? <Link to={item.url}>{item.title}</Link> : <a href={item.href}>{item.title}</a>}
                  </li>
              </>
          );
        })}
      </ul>
    </>
  );
};

export default FooterList;
