import {BrowserRouter, Routes, Route} from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

// Start Digital Content
const AllDigitalContentLazyLoading = React.lazy(() => import('./pages/DigitalContent/all-digital-content'));
const RoadToRiyadhLazyLoading = React.lazy(() => import('./pages/The road to Riyadh/road-to-Riyadh'));
const RoadDetailsLazyLoading = React.lazy(() => import('./pages/The road to Riyadh/road-details'));
const NewspaperLazyLoading = React.lazy(() => import('./pages/DigitalContent/newspaper'));
const NewspaperArticleDetailsLazyLoading = React.lazy(() => import('./pages/DigitalContent/newspaper-article-details'));
const AuthorsLazyLoading = React.lazy(() => import('./pages/Authors/Authors'));
const AuthorDetailsLazyLoading = React.lazy(() => import('./pages/Authors/AuthorDetails'));
// End Digital Content

// Start Portal
// const PortalLazyLoading = React.lazy(() => import("./pages/Portal/Portal"));
import Layout from "./pages/Layout/Layout";
// End Portal

// Start Profile
const PersonalProfileLazyLoading = React.lazy(() => import('./pages/PersonalProfile/personal-profile'));
const AccountManagementLazyLoading = React.lazy(() => import("./pages/PersonalProfile/account-management"));
const EmailVerificationLazyLoading = React.lazy(() => import('./pages/Login/emailVerification'));
const PhoneVerificationLazyLoading = React.lazy(() => import('./pages/Login/phoneVerification'));
const ResetPasswordLazyLoading = React.lazy(() => import('./pages/Login/reset-password'));
// End Profile

// Start Media Center
const PhotosLazyLoading = React.lazy(() => import("./pages/MediaCenter/Photos/Photos"));
const VideosLibraryLazyLoading = React.lazy(() => import("./pages/MediaCenter/Videos/VideosLibrary"));
const InfographicsPhotosLazyLoading = React.lazy(() => import("./pages/Infographics/Photos/Photos"));
const KingHallLazyLoading = React.lazy(() => import("./pages/MediaCenter/KingHall/KingHall"));
// End Media Center

// Start E-Services
const EservicesLazyLoading = React.lazy(() => import("./pages/Eservices/Eservices"));
const EserviceDetailsLazyLoading = React.lazy(() => import("./pages/Eservices/EserviceDetails"));
const EserviceFormLazyLoading = React.lazy(() => import("./pages/Eservices/EserviceForm"));
// End E-Services

// Start AudioBooks
const AudioBooksLazyLoading = React.lazy(() => import('./pages/MediaCenter/AudioBooks/AudioBooks'));
const OpenAccessDetailsLazyLoading = React.lazy(() => import('./pages/Portal/OpenAccess/open-access-details'));
// End AudioBooks

// Start Projects
const ProjectViewLazyLoading = React.lazy(() =>
  import("./pages/Projects/ProjectView")
);
const ProjectsLazyLoading = React.lazy(() =>
  import("./pages/Projects/projects")
);
// End Projects

// Start About Us
const AboutLazyLoading = React.lazy(() => import("./pages/AboutUs/AboutUs"));
const DarahBoardLazyLoading = React.lazy(() => import("./pages/Darah-Board/darah-board"));
const DepartmentAndCenterLazyLoading = React.lazy(() => import("./pages/DepartmentsAndCenters/departments-and-centers"));
const DepartmentsDetailsLazyLoading = React.lazy(() => import('./pages/DepartmentsAndCenters/department-details'));
const MembershipLazyLoading = React.lazy(() => import('./pages/Membership/Membership'));
const KingAndDarahLazyLoading = React.lazy(() => import('./pages/KingAndDarah/KingAndDarah'));
// End About Us

// Start Help&Support
const FAQLazyLoading = React.lazy(() => import("./pages/FAQ/FAQ"));
const BeneficiaryVoiceLazyLoading = React.lazy(() =>
  import("./pages/BeneficiaryVoice/beneficiary-voice")
);
const SearchGateLazyLoading = React.lazy(() =>
  import("./pages/SearchGate/search-gate")
);
const ServicesforpeopleLazyLoading = React.lazy(() =>
  import("./pages/Services-for-people/Services-for-people")
);
const SignLanguageLazyLoading = React.lazy(() =>
  import("./pages/SignLanguage/SignLanguage")
);
const ContactUsLazyLoading = React.lazy(() =>
  import("./pages/ContactUs/ContactUs")
);
// End Help&Support

// Start News
const NewsLazyLoading = React.lazy(() => import('./pages/News/News'));
const NewsDetailsLazyLoading = React.lazy(() => import('./pages/News/news-details'));
// End News

// Start Event
const EventsLazyLoading = React.lazy(() => import('./pages/Events/Events'));
const EventsDetailsLazyLoading = React.lazy(() => import('./pages/Events/events-details'));
import EventReservation from "./pages/Events/event-reservation";
// End Event

// Start Reports
const ReportsLazyLoading = React.lazy(() => import('./pages/MediaCenter/Reports/Reports'));
const ReportsPageLazyLoading = React.lazy(() => import('./pages/MediaCenter/Reports/ReportsPage'));
const ReportsPageDetailsLazyLoading = React.lazy(() => import('./pages/MediaCenter/Reports/ReportsPageDetails'));

// End Reports

// General
const PrivacyPolicyLazyLoading = React.lazy(() => import('./pages/Privacy-Policy/Privacy-Policy'));
import { TermsAndConditions } from "./pages/terms-and-conditions/TermsAndConditions";
import PageLoader from "./components/PageLoader/page-loader";
import ProtectedRoute from "./protected-route";
import ScrollToTop from "./common/ScrollToTop/ScrollToTop";
import { useZoom } from './context/zoomContext/ZoomContext'
import { useContrast } from './context/contrastContext/ContrastContext'
import Notfound from "./pages/Notfound/Notfound";
import BlankPageLoader from "./components/PageLoader/blank-page-loader";
import Portal from "./pages/Portal/Portal";


function App() {
  const { zoomLevel } = useZoom();
  const { contrastLevel } = useContrast();
  const { i18n } = useTranslation();

  return (<>
    {/*<div className={`zoomed contrasted`} style={{ transform: `scale(${(zoomLevel) / 100})`, filter: `contrast(${contrastLevel}%)` }}>*/}
    <div className={`contrasted`} dir = {i18n.language === "en" ? "ltr" : "rtl"}>
       <BrowserRouter>
        <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          {/* <Route path="/" element={
          <React.Suspense fallback={<BlankPageLoader/>}>
            <PortalLazyLoading/>
          </React.Suspense>
          }
          /> */}
          
          <Route path="/" element={<Portal />} />

          <Route path="/account-management" element={
          <React.Suspense fallback={<BlankPageLoader/>}>
            <AccountManagementLazyLoading/>
          </React.Suspense>
          }
          />

          {/* Start E-Services */}
          <Route path="/e-services" element={
          <React.Suspense fallback={<BlankPageLoader/>}>
            <EservicesLazyLoading/>
          </React.Suspense>
          }
          />
          
          <Route path="/e-services/service-details/:slug" element={
          <React.Suspense fallback={<BlankPageLoader/>}>
            <EserviceDetailsLazyLoading/>
          </React.Suspense>
          }
          />
          <Route path="/e-services/forms/:slug" element={
          <React.Suspense fallback={<BlankPageLoader/>}>
            <EserviceFormLazyLoading/>
          </React.Suspense>
          }
          />
          <Route path="/e-services/forms/:slug" element={<ProtectedRoute />}>
          </Route>
          {/* End E-Services */}

          {/* Start new Digital Content */}
          <Route path="/digital-content/all" element={<React.Suspense fallback={<BlankPageLoader />}><AllDigitalContentLazyLoading /></React.Suspense>} />

          <Route path="/digital-content/newspaper/:newspaperSlug" element={<React.Suspense fallback={<BlankPageLoader />}><NewspaperLazyLoading /></React.Suspense>} />
          <Route path="/digital-content/newspaper/:newspaperSlug/newspaper-article-details/:articleSlug" element={<React.Suspense fallback={<BlankPageLoader />}><NewspaperArticleDetailsLazyLoading /></React.Suspense>} />
          <Route path="/digital-content/newspaper/newspaper-article-details/:articleSlug" element={<React.Suspense fallback={<BlankPageLoader />}><NewspaperArticleDetailsLazyLoading /></React.Suspense>} />

          <Route path="/digital-content/magazine/:newspaperSlug" element={<React.Suspense fallback={<BlankPageLoader />}><NewspaperLazyLoading /></React.Suspense>} />
          <Route path="/digital-content/magazine/:newspaperSlug/magazine-article-details/:articleSlug" element={<React.Suspense fallback={<BlankPageLoader />}><NewspaperArticleDetailsLazyLoading /></React.Suspense>} />
          <Route path="/digital-content/magazine/magazine-article-details/:articleSlug" element={<React.Suspense fallback={<BlankPageLoader />}><NewspaperArticleDetailsLazyLoading /></React.Suspense>} />

          <Route path="/digital-content/road/:roadSlug" element={<React.Suspense fallback={<BlankPageLoader />}><RoadToRiyadhLazyLoading /></React.Suspense>} />
          <Route path="/digital-content/road/:roadSlug/road-details/:roadSlugDetails" element={<React.Suspense fallback={<BlankPageLoader />}><RoadDetailsLazyLoading /></React.Suspense>} />

          <Route path="/digital-content/encyclopedia/:encyclopediaSlug" element={<React.Suspense fallback={<BlankPageLoader />}><AuthorsLazyLoading /></React.Suspense>} />
          <Route path="/digital-content/encyclopedia/:encyclopediaSlug/encyclopedia-details/:encyclopediaSlugDetails" element={<React.Suspense fallback={<BlankPageLoader />}><AuthorDetailsLazyLoading /></React.Suspense>} />
          {/* End of Digital Content */}
          
          {/* Start Media Center */} 
            {/* Start News */}
            <Route
                path="/news"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <NewsLazyLoading />
                  </React.Suspense>
                }
              />
            <Route
                path="news/news-details/:newsId"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <NewsDetailsLazyLoading />
                  </React.Suspense>
                }
              />
            {/* End News */}

            {/* Start Events */}
              <Route
                path="/events"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <EventsLazyLoading />
                  </React.Suspense>
                }
              />
              <Route
                path="/events/event-details/:slug"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <EventsDetailsLazyLoading />
                  </React.Suspense>
                }
              />
              {/* <Route path="/events/event-details/events-reservation/:slug" element={<EventReservation />} /> */}
            {/* End Events */}

            {/* Start Reports */}
            <Route
              path="/media-center/reports"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <ReportsLazyLoading />
                </React.Suspense>
            }
            />
            <Route path="/media-center/reports/reports-characters" element={<React.Suspense fallback={<BlankPageLoader />}><ReportsPageLazyLoading /></React.Suspense>} />
            <Route path="/media-center/reports/reports-places" element={<React.Suspense fallback={<BlankPageLoader />}><ReportsPageLazyLoading /></React.Suspense>} />
            <Route path="/media-center/reports/reports-events" element={<React.Suspense fallback={<BlankPageLoader />}><ReportsPageLazyLoading /></React.Suspense>} />
            
            <Route path="/media-center/reports/reports-characters/reports-details-character/:reportId" element={<React.Suspense fallback={<BlankPageLoader />}><ReportsPageDetailsLazyLoading /></React.Suspense>} />
            <Route path="/media-center/reports/reports-places/reports-details-place/:reportId" element={<React.Suspense fallback={<BlankPageLoader />}><ReportsPageDetailsLazyLoading /></React.Suspense>} />
            <Route path="/media-center/reports/reports-events/reports-details-event/:reportId" element={<React.Suspense fallback={<BlankPageLoader />}><ReportsPageDetailsLazyLoading /></React.Suspense>} />
                       
            {/* End Reports */}

            {/* Start Free Books */}
            <Route path="/open-books" element={<React.Suspense fallback={<BlankPageLoader />}><OpenAccessDetailsLazyLoading /></React.Suspense>} />
            {/* End Free Books */}

            {/* Start Media */}
            <Route
                path="/media-center/photos"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <PhotosLazyLoading />
                  </React.Suspense>
                }
              />
              <Route
                path="/media-center/videos"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <VideosLibraryLazyLoading />
                  </React.Suspense>
                }
              />
              <Route
                path="/media-center/audio_books"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <AudioBooksLazyLoading />
                  </React.Suspense>
                }
              />
              <Route
                path="/media-center/infographics"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <InfographicsPhotosLazyLoading />
                  </React.Suspense>
                }
              />
              <Route
                path="/media-center/hall/:slug"
                element={
                  <React.Suspense fallback={<BlankPageLoader />}>
                    <KingHallLazyLoading />
                  </React.Suspense>
                }
              />
            {/* End Media */}

          {/* End Media Center */}

          {/* Start Projects */}
          <Route
            path="/projects"
            element={
              <React.Suspense fallback={<BlankPageLoader />}>
                <ProjectsLazyLoading />
              </React.Suspense>
            }
          />
          <Route
            path="/projects/project-details/:projectId"
            element={
              <React.Suspense fallback={<BlankPageLoader />}>
                <ProjectViewLazyLoading />
              </React.Suspense>
            }
          />
          {/* End Projects */}


          {/* Start About Us */}
          <Route path="/about-us" element={
          <React.Suspense fallback={<BlankPageLoader/>}>
            <AboutLazyLoading />
          </React.Suspense>
           }
          />
          <Route
            path="/board"
            element={
              <React.Suspense fallback={<BlankPageLoader/>}>
                <DarahBoardLazyLoading />
              </React.Suspense>
            }
          />
          <Route path="/departments" element={
            <React.Suspense fallback={<BlankPageLoader/>}>
            <DepartmentAndCenterLazyLoading />
            </React.Suspense>
            }
           />
          <Route
            path="/departments/departments-details/:id"
            element={
              <React.Suspense fallback={<BlankPageLoader />}>
                <DepartmentsDetailsLazyLoading />
              </React.Suspense>
            }
          />
          <Route
            path="/membership"
            element={
              <React.Suspense fallback={<BlankPageLoader />}>
                <MembershipLazyLoading />
              </React.Suspense>
            }
          />
          <Route path="/king-and-darah" element={
          <React.Suspense fallback={<BlankPageLoader/>}>
            <KingAndDarahLazyLoading />
          </React.Suspense>
           }
          />
          {/* End About Us */}

          
          {/* Start Help&Support */}
            {/* <Route
              path="/helpandsupport/signlanguage"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <SignLanguageLazyLoading />
                </React.Suspense>
              }
            /> */}
            <Route
              path="/accessibility-services"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <ServicesforpeopleLazyLoading />
                </React.Suspense>
              }
            />
            <Route
              path="/contact-us"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <ContactUsLazyLoading />
                </React.Suspense>
              }
            />
            <Route
              path="/searchgate"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <SearchGateLazyLoading />
                </React.Suspense>
              }
            />
            <Route
              path="/searchdarah"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <SearchGateLazyLoading />
                </React.Suspense>
              }
            />
            <Route
              path="/beneficiaryVoice"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <BeneficiaryVoiceLazyLoading />
                </React.Suspense>
              }
            />
            <Route
              path="/helpandsupport/faq"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <FAQLazyLoading />
                </React.Suspense>
              }
            />
            {/* End Help&Support */}

          <Route path="/terms-conditions/:type" element={<TermsAndConditions />} />
            <Route
              path="/privacy-policy"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <PrivacyPolicyLazyLoading />
                </React.Suspense>
              }
            />
        
          {/* Auth Required */}
          <Route path="/profile" element={<ProtectedRoute />}>
            <Route
              path="/profile"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <PersonalProfileLazyLoading />
                </React.Suspense>
              }
            />
          </Route>

        </Route>
        {/* End Layout */}        
        <Route
              path="/resetPassword"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <ResetPasswordLazyLoading />
                </React.Suspense>
              }
            />
        <Route
              path="/verificationEmail"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <EmailVerificationLazyLoading />
                </React.Suspense>
              }
            />
            <Route
              path="/verificationPhone"
              element={
                <React.Suspense fallback={<BlankPageLoader />}>
                  <PhoneVerificationLazyLoading />
                </React.Suspense>
              }
            />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
    </div>
  </>);
}
export default App;
