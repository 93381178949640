import { axios, baseURL, config } from "../axios";

export const getSettings = () => {
  return axios
    .get(baseURL + "portal/settings", config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};