import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "./HelpAndSupport.css";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import { config } from "../../api/axios";

const HelpAndSupport = ({ Links }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const handleLanguageToggle = () => {
    const newLanguage = language === 'en' ? 'ar' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    config.headers["Accept-Language"] = i18n.language;
    window.location.reload();
  };
  const shareOnSocialItems = [
    {
      name: FacebookShareButton,
      quote: `${Links?.message_close}`,
      icon: "fab fa-facebook-f"
    },
    {
      name: TwitterShareButton,
      hashtags: [`${Links?.site_title}, ${Links?.msgmain_close}`],
      icon: "",
      svg: () => (<svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.22184 6.01767L13.0001 0.583008H11.8682L7.71749 5.30089L4.40477 0.583008H0.583008L5.59357 7.71796L0.583008 13.4163H1.71491L6.09535 8.43303L9.59457 13.4163H13.4163M2.12344 1.41819H3.86235L11.8674 12.6222H10.128"
            fill="#fff"/>
      </svg>)
    },
    {
      name: LinkedinShareButton,
      title: `${Links?.message_close}`,
      icon: "fab fa-linkedin"
    },
    {name: WhatsappShareButton, title: `${Links?.message_close}`, icon: "fab fa-whatsapp"},
    {name: TelegramShareButton, title: `${Links?.message_close}`, icon: "fab fa-telegram"}
  ]
  return (
      <>
        <div className="position-relative">
          {/* <div className="row mx-0"> */}
          <div className="">
            <button
              onClick={handleShow}
              className={`btn btn-primary btn-help-support ${i18n.language == "en" ? "btn-sideRight" : "btn-sideleft" }`}
              // style={{left: i18n.language == "en" ? "-59px" : ""}}
              id="modal_view_left"
              data-toggle="modal"
              data-target="#get_quote_modal"
            >
              {" "}
              {t('help_support.title')}
            </button>
          </div>
        {/* </div> */}
      </div>
      {/* -----Model----- */}
      <Modal
        show={show}
        onHide={handleClose}
        className={`modal modal_outer ${i18n.language == "en" ? "right_modal" : "left_modal" } fade`}
        id="get_quote_modal"
        tabIndex={-1}
        role="dialog"
        style={{paddingRight: 0}}
        aria-labelledby="myModalLabel2"
      >
        <div className="modal-dialog" role="document">
          <form method="post" id="get_quote_frm">
            <div className="modal-content ">
              <div className={`modal-header ${i18n.language == "en" ? "en" : ""}`}>
                <h3 className="modal-title" style={{color: "#425260"}}>
                  {t('help_support.title')}
                </h3>
                <span
                  style={{color: "#425260"}}
                  className="close-button close"
                  // variant="secondary"
                  onClick={handleClose}
                >
                  &times;
                </span>
              </div>
              <div className="modal-body get_quote_view_modal_body">
                <div className="list-group list-support">
                  <Link onClick={handleClose}
                    to="/helpandsupport/faq"
                    style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                    className="list-group-item">
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-question`}></i>
                    {t('help_support.faqs')}
                    {" "}
                  </Link>

                  <Link
                    onClick={handleClose}
                    to="/beneficiaryVoice"
                    style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                    className="list-group-item">
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-user-check`}></i>
                    {t('help_support.UserFeedback')}
                    {" "}
                  </Link>

                  <Link
                    state={{ isPublicSearch: true, searchKey: null }}
                    onClick={handleClose}
                    to="/searchgate"
                    style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                    className="list-group-item">
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-file-search`}></i>
                    {t('help_support.SearchPortal')}
                    {" "}
                  </Link>

                  <Link
                    onClick={handleClose}
                    to={"/accessibility-services"}
                    style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                    className="list-group-item">
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-wheelchair`}></i>
                    {t('help_support.ServicesPersonsDisabilities')}
                    {" "}
                  </Link>

                  {/* <Link
                    onClick={handleClose}
                    to={"/helpandsupport/signlanguage"}
                    style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                    className="list-group-item">
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-american-sign-language-interpreting`}></i>{" "}
                    {t('help_support.sign_lang')}
                    {" "}
                  </Link> */}

                  <Link
                    onClick={handleClose}
                    style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                    to={"/contact-us"} className="list-group-item">
                    {" "}
                    <i className={`${i18n.language == "en" ? "en" : ""} fal fa-phone`}></i>
                    {t('help_support.contact_us')}
                    {" "}
                  </Link>
                  {/* <div>
                    <div
                    style={{direction: i18n.language == "en" ? "ltr" : "rtl"}}
                    className={"d-flex justify-content-between align-items-center HeaderShareNav"}>
                      <div className={"HeaderShareIcons"}>
                        {shareOnSocialItems?.map((socialItem, index) => {
                          const SocialButton = socialItem.name; // get the component from the object
                          return (
                            <SocialButton onClick={handleClose} key={index} title={socialItem.title} description={socialItem.description} quote={socialItem.quote} hashtags={socialItem.hashtags} url={Links?.site_url}>
                              {
                                socialItem?.icon ? <i className={socialItem.icon}></i> : socialItem?.svg?.()
                              }
                            </SocialButton>
                          );
                        })}
                      </div>
                      <div role={"button"} onClick={() => handleLanguageToggle()} className="lang-icon p-2">
                        {language === 'en' ? 'EN' : 'AR'}  <i className={"far fa-globe"}></i>
                      </div >
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="modal-footer"></div>
            </div>
            {/* <!-- //modal-content --> */}
          </form>
        </div>
        {/* <!-- modal-dialog --> */}
      </Modal>
    </>
  );
};
export default HelpAndSupport;
