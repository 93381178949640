import React, {useState, useEffect, useRef} from "react";
import "./BackToTopButton.css";
import styles from "../../pages/Portal/Slider/slider.module.css";
import darahNews from "../../assets/videos/darah-april-2023.mp4";
import {Dropdown} from "react-bootstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import Button from "react-bootstrap/Button";
import {useLocation} from "react-router-dom";
import i18n from './../../i18next';

const BackToTopButton = ({Links}) => {
  // const [showButton, setShowButton] = useState(false);
  const isPortalPage = useLocation().pathname === "/";
  // useEffect(() => {
  //   const checkScrollHeight = () => {
  //     if (!showButton && window.pageYOffset >= 50) {
  //       setShowButton(true);
  //     } else if (showButton && window.pageYOffset < 50) {
  //       setShowButton(false);
  //     }
  //   };

  //   window.addEventListener("scroll", checkScrollHeight);
  //   return () => {
  //     window.removeEventListener("scroll", checkScrollHeight);
  //   };
  // }, [showButton]);

  // const scrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };

  const [showVideo, setShowVideo] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (videoRef.current && !videoRef.current.contains(event.target)) {
        setShowVideo(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleVideoIconClick = () => {
    setShowVideo(true);
  };

  const handleVideoClose = () => {
    setShowVideo(false);
  };

  const shareOnSocialItems = [
    {
      name: FacebookShareButton,
      quote: `${Links?.message_close}`,
      icon: "fab fa-facebook-f",
      svg:""
    },
    {
      name: TwitterShareButton,
      hashtags: [`${Links?.site_title}, ${Links?.msgmain_close}`],
      icon: "fab fa-twitter",
      svg: <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none"><path d="M8.22232 6.01792L13.0006 0.583252H11.8687L7.71797 5.30113L4.40526 0.583252H0.583496L5.59406 7.7182L0.583496 13.4166H1.7154L6.09584 8.43328L9.59506 13.4166H13.4168M2.12392 1.41843H3.86284L11.8678 12.6224H10.1285" fill="white"></path></svg>
    },
    {
      name: LinkedinShareButton,
      title: `${Links?.message_close}`,
      icon: "fab fa-linkedin",
      svg:""
    },
    {name: WhatsappShareButton, title: `${Links?.message_close}`, icon: "fab fa-whatsapp", svg:""},
    {name: TelegramShareButton, title: `${Links?.message_close}`, icon: "fab fa-telegram", svg:""}
  ]

  return (
      <>
        {isPortalPage && (
            <div
            className={`back-to-top position-fixed ${i18n.language == "en" ? "en" : ""}`}
            data-offset-top="10"
            data-spy="affix"
            id="back-to-top"
        >
          <div className={`align-self-end d-flex flex-column me-auto`}>
            <div ref={videoRef} className={"position-relative"}>
              <Button onClick={handleVideoIconClick} style={{borderRadius: i18n.language == "en"? "8px 0 0 8px" : "0 8px 8px 0"}} className={"portalVideoBtn d-flex align-items-center justify-content-center"}>
                {/* <i className="far fa-video fs-5 pt-0"></i> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12.53 20.42H6.21C3.05 20.42 2 18.32 2 16.21V7.78999C2 4.62999 3.05 3.57999 6.21 3.57999H12.53C15.69 3.57999 16.74 4.62999 16.74 7.78999V16.21C16.74 19.37 15.68 20.42 12.53 20.42Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.5202 17.1L16.7402 15.15V8.84001L19.5202 6.89001C20.8802 5.94001 22.0002 6.52001 22.0002 8.19001V15.81C22.0002 17.48 20.8802 18.06 19.5202 17.1Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </Button>
              {showVideo && (
                  <div className={`${i18n.language == "en" ? styles.videoEn : ""} ${styles.videoModal}`}>
                    <i className="fas fa-times d-flex justify-content-end fs-5 text-darah" onClick={handleVideoClose}></i>
                    <video autoPlay loop controls width={"400px"} height={"250px"}>
                      <source src={darahNews} type="video/mp4" />
                      {i18n.language == "en" ? "Your browser does not support the video tag." : "هذا المتصفح لا يدعم هذا الفيديو"}
                    </video>
                  </div>
              )}
            </div>
            <Dropdown className={i18n.language == "en" ? "dropdownMenu" : ""}>
              <Dropdown.Toggle id="dropdown-autoclose-true" style={{borderRadius: i18n.language == "en"? "8px 0 0 8px" : "0 8px 8px 0"}} className={"p-1 d-flex justify-content-center align-items-center portalShareBtn"}>
                {/* <i className={"far fa-share-alt fs-5"}></i> */}
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M16.9609 6.16998C18.9609 7.55998 20.3409 9.76998 20.6209 12.32" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.49023 12.37C3.75023 9.82997 5.11023 7.61997 7.09023 6.21997" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.18945 20.94C9.34945 21.53 10.6695 21.86 12.0595 21.86C13.3995 21.86 14.6595 21.56 15.7895 21.01" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.0593 7.70001C13.5946 7.70001 14.8393 6.45537 14.8393 4.92001C14.8393 3.38466 13.5946 2.14001 12.0593 2.14001C10.5239 2.14001 9.2793 3.38466 9.2793 4.92001C9.2793 6.45537 10.5239 7.70001 12.0593 7.70001Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M4.83078 19.92C6.36613 19.92 7.61078 18.6753 7.61078 17.14C7.61078 15.6046 6.36613 14.36 4.83078 14.36C3.29543 14.36 2.05078 15.6046 2.05078 17.14C2.05078 18.6753 3.29543 19.92 4.83078 19.92Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M19.1706 19.92C20.706 19.92 21.9506 18.6753 21.9506 17.14C21.9506 15.6046 20.706 14.36 19.1706 14.36C17.6353 14.36 16.3906 15.6046 16.3906 17.14C16.3906 18.6753 17.6353 19.92 19.1706 19.92Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </Dropdown.Toggle>
              <Dropdown.Menu className={`transition rounded-1`}>
                <div className={"social-top d-flex justify-content-between p-1 socialShareSide"}>
                  {shareOnSocialItems.map((socialItem, index) => {
                    const SocialButton = socialItem.name; // get the component from the object
                    return (
                        <SocialButton key={index} title={socialItem.title} description={socialItem.description} quote={socialItem.quote} hashtags={socialItem.hashtags} resetButtonStyle={false} url={Links?.site_url} className={"dropdown-item shareIcons"}>
                          {socialItem?.svg ? socialItem?.svg : 
                          <i className={socialItem.icon}></i>
                          }
                        </SocialButton>
                    );
                  })}
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {/* <button
              hidden={!showButton}
              onClick={scrollToTop}
              className="btn btn-primary backToTopBtn"
              title="Back to Top"
          >
            <i className="fa fa-angle-double-up"></i>
          </button> */}
        </div>
        )}
      </>
  );
};

export default BackToTopButton;
