import { axios, baseURL, config } from "../axios";

export const getEserviceDetails = async (slug) => {

    try {
        const { data } = await axios.get(baseURL + `services/${slug}`, config);
        return data;
    } catch (error) {
        throw error;
    }
};

export const getEserviceFormData = async (slug) => {

    try {
        const { data } = await axios.get(baseURL + `services/forms/${slug}`, config);
        return data;
    } catch (error) {
        throw error;
    }
};

export const postFormData = async (formData) => {
    try {
        const { data } = await axios.post(baseURL + 'services/orders/store', formData, config);
        return data;
    } catch (error) {
        throw error;
    }
};

export const getServiceOrders = async (page = 1, limit = 3) => {
    try {
        const { data } = await axios.get(baseURL + `services/orders/my-orders?page=${page}&limit=${limit}`, config);
        return data;
    } catch (error) {
        throw error;
    }
};

export const getServiceOrder = async (codeOrder) => {
    try {
        const { data } = await axios.get(baseURL + `services/orders/show/${codeOrder}`, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const postServiceOrderTrack = async (formData) => {
    try {
        const { data } = await axios.post(baseURL + "services/orders/tracks", formData, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getServiceOrderTrack = async (codeOrder) => {
    try {
        const { data } = await axios.get(baseURL + `services/orders/tracks/${codeOrder}`, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const updateServiceOrderTrack = async (codeOrder, formData) => {
    try {
        const { data } = await axios.put(baseURL + `services/orders/update/${codeOrder}`, formData, config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const getServiceTerms = async () => {
    try {
        const { data } = await axios.get(baseURL + 'e-services/pages/terms', config);
        return data;
    } catch (error) {
        throw error;
    }
}

export const cancelServiceOrder = async (codeOrder) => {
    try {
        const {data} = await axios.put(`${baseURL}services/orders/update/${codeOrder}/cancel`, {}, config);
        return data;
    } catch(error) {
        throw error
    }
}