import Modal from 'react-bootstrap/Modal';
import "./show-big-image.css"
import { useTranslation } from 'react-i18next';
import defaultImage from "../../assets/images/default_image.jpg";

function ShowImageBigger({showBigImage, setShowBigImage, image}) {
  const { t, i18n } = useTranslation();
  const handleCloseBigImage = () => setShowBigImage(false);

  return (
    <>
     
     <Modal id="bigImagePopup" centered show={showBigImage} size="md" onHide={handleCloseBigImage}>
     <div className="px-4 pb-4 pt-3">
        <Modal.Header
          className={i18n.language == "en" ? "bigImageHeaderModel_en flex-row-reverse border-0" : "bigImageHeaderModel border-0"}
          closeButton
        >
        </Modal.Header>
        <Modal.Body>
          <img src={image} alt='صورة مكبرة' onError={(e) => { e.target.src = defaultImage; }} className='w-100'  />
        </Modal.Body>
      </div>
      </Modal>
    </>
  );
}

export default ShowImageBigger;