import React from "react";
import { useNavigate } from 'react-router-dom';
import Row from "react-bootstrap/Row";
import styles from "./navitems.module.css";

const NavItems = ({ links }) => {
    const navigate = useNavigate();

    const handleNavigate = (path, backSteps) => {
        if (path === "#") {
            return;
        }

        if (backSteps) {
            navigate(-backSteps);
        } else {
            navigate(path);
        }
    };

    return (
        <div className={"mb-4"}>
            <Row className="w-100 mx-0">
                <div className={`${styles.navTitles} flex-wrap d-flex justify-content-start`}>
                    {links.map((link, index) => (
                        <React.Fragment key={index}>
                            {link.path === "#" ? (
                                <>
                                <span data-toggle="tooltip" data-html="true" title={link?.label} className={`${link?.lastOne ? styles.lastOneStyle : "" } ${link.path != "#"? "cursor-pointer" : ""}`}>{link?.label?.length > 50 ? link?.label?.slice(0, 50)+"..." : link?.label}</span>
                            <span className={"mx-0"}> {" "} {index !== links.length - 1 && "/"}</span>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={`${link?.lastOne ? styles.lastOneStyle : "" } ${link.path != "#"? "cursor-pointer" : ""}`}
                                        onClick={() => handleNavigate(link.path, link.backSteps)}
                                        data-toggle="tooltip" data-html="true" title={link?.label}
                                    >
                                        {link?.label?.length > 50 ? link?.label?.slice(0, 50)+"..." : link?.label}
                                    </div>
                                    <span className="mx-0">{index !== links.length - 1 && "/"}</span>
                                </>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Row>
        </div>
    );
};

export default NavItems;