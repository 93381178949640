import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/fontawesome/css/all.min.css";
import "./assets/plugins/bootstrap-rtl/css/bootstrap-rtl.min.css";
import "./assets/fonts/Century_Gothic_Regular.ttf";
import "./assets/fonts/Droid-Arabic-Kufi.ttf";
import "./index.css";
import "./i18next";
import App from "./App";
import "./index.css";
import "./global.css";
import "./common/Override/css/tabs.css";
import { AuthProvider } from "./context/authContext/authContext";
import { ZoomProvider } from "./context/zoomContext/ZoomContext";
import { ContrastProvider } from "./context/contrastContext/ContrastContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <ZoomProvider>
      <ContrastProvider>
        <App />
      </ContrastProvider>
    </ZoomProvider>
  </AuthProvider>
);
