import MenuItems from "./MenuItems";
const Dropdown = ({ submenus, dropdown, depthLevel, setCollapseMenu }) => {
    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";
    return (
        <ul className={`dropdown-menu dropdownWidthSM ${dropdownClass} ${dropdown ? "show" : ""}`} role={"menu"}>
            {submenus.map((submenu, index) => (
                <MenuItems depthLevel={depthLevel}   items={submenu} key={index} setCollapseMenu={setCollapseMenu} />
            ))}
        </ul>
    );
};

export default Dropdown;