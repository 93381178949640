// ZoomContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const ZoomContext = createContext();

export const ZoomProvider = ({ children }) => {
  let [zoomLevel, setZoomLevel] = useState(16); // Initial zoom level (in percentage)
  //   useEffect(() => {
  //     if(localStorage.getItem("zoom")){
  //         setZoomLevel(localStorage.getItem("zoom"))
  //     };
  //   }, []);
  useEffect(() => {
    const storedZoomLevel = localStorage.getItem("zoomLevel");
    if (storedZoomLevel) {
      setZoomLevel(parseFloat(storedZoomLevel));
    }
  }, []);

  // Update local storage whenever zoomLevel changes
  useEffect(() => {
    if (zoomLevel > 30) {
      setZoomLevel(30);
    }
    localStorage.setItem("zoomLevel", zoomLevel.toString());
    document.documentElement.style.fontSize = `${zoomLevel}px`;
  }, [zoomLevel]);
  return (
    <ZoomContext.Provider value={{ zoomLevel, setZoomLevel }}>
      {children}
    </ZoomContext.Provider>
  );
};

export const useZoom = () => {
  return useContext(ZoomContext);
};
