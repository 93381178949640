import HelpAndSupport from "../../components/HelpAndSupport/help-and-support";
import Footer from "../../components/Footer/Footer";
import styles from "./Layout.module.css";
import Header from "../../components/Header/Header";
import { Outlet } from "react-router-dom";
// import ToastContainer from "react-bootstrap/ToastContainer";
// import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { getSettings } from "../../api/services/Settings-api";
import BackToTopButton from "../../components/BackToTopButton/BackToTopButton";
import ChatWhatsApp from "../../components/ChatWhatsApp/ChatWhatsApp";

const Layout = () => {

  const [Links, setLinks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
      getSettings()
      .then((res) => {
        setLinks(res.data);
        })
        .catch((err) => {
          setError("Some error happened!");
        });
    }, []);

    return (
    <>
      <Header Links={Links} />
        <BackToTopButton Links={Links} />
        <div className={styles.bodyInner}>
            {/* <ToastContainer /> */}
            <HelpAndSupport Links={Links} />
        <Outlet />
      </div>
      <a href={"https://wa.me/" + Links?.whatsapp?.replace(/\D/g, "")}
      target={"_blank"}
      aria-label="phone"
      >
        <ChatWhatsApp/>
      </a>
      <Footer Links={Links}/>
    </>
  );
};

export default Layout;
